<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-inline"><i class="fa fa-users"></i> All Accountant</h4>
          <router-link class="float-right btn btn-success" :to="{ name: 'System Admin Add Accountant' }">Add Accountant <i
                class="fa fa-plus"></i></router-link>

          <hr class="my-4" />
          <v-client-table :columns="columns" :data="managerData" :options="options" :theme="theme" id="dataTable">
            <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-sm btn-success w-100"
                  @click="deactiveUser(props.row.managerid, 'manager')">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-sm btn-danger w-100" @click="activeUser(props.row.managerid, 'manager')">Inactive</button>
              </span>
            </div>
            <span slot="creation_date" slot-scope="props">{{
                props.row.creation_date | moment("M/D/YYYY")
            }}</span>
            <div slot="actions" slot-scope="props" data-boundary="window">
              <b-button class="mr-2 btn-sm" @click="sendInfo(props.row)"><i class="fa fa-edit"></i> Edit</b-button>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="editManager" ref="editModal" title="Edit Accountant" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label>First Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control"
            placeholder="First Name" />
        </b-input-group>
        <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')"
          class="help is-danger">The first name is required.</span>
        <label>Last Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control"
            placeholder="Last Name" />
        </b-input-group>
        <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')"
          class="help is-danger">The last name is required.</span>
        <div v-if="this.authority !== 6">
          <hr />
          <label>Authority</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-hashtag"></i></b-input-group-text>
            </b-input-group-prepend>
            <select class="select-account form-control" name="authority" v-model="authority">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </b-input-group>

          <i v-show="errors.has('authority')" class="fa fa-exclamation-triangle"></i><span
            v-show="errors.has('authority')" class="help is-danger">The authority number is required.</span>

          <label>Pass Limit</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
            </b-input-group-prepend>
            <b-form-input type="number" v-model="passlimit" v-validate="'required'" name="passlimit"
              class="form-control" placeholder="dollar-sign Limit" />
          </b-input-group>
          <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
            v-show="errors.has('passlimit')" class="help is-danger">The dollar-sign limit number is required.</span>
        </div>
        <hr />
        <label>Phone Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone"
            class="form-control" placeholder="Phone Number" />
        </b-input-group>
        <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')"
          class="help is-danger">{{
              errors.first("phone")
          }}</span>

        <label>Fax Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="fax" v-validate="'digits:10'" name="fax" class="form-control"
            placeholder="Fax Number" />
        </b-input-group>
        <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')"
          class="help is-danger">{{
              errors.first("fax")
          }}</span>

        <hr />
        <label>Email</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control"
            placeholder="Email" />
        </b-input-group>
        <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')"
          class="help is-danger">The email is required.</span>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1">
            <p class="ml-3 mb-0">
              Account <span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span>
            </p>
          </b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle ml-2 mt-3 mb-2"></i><span v-show="error"
          class="help is-danger">Error. Please contact admin.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { ClientTable, Event } from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'WtsVendor',
  components: {
    ClientTable,
    Event,
    name: "AccountantAuthority",
    components: {
      ClientTable,
      Event,
    },
  },
  data() {
    return {
      authorityType: 1,
      columns: [
        "status",
        "email",
        "fname",
        "lname",
        "phone",
        "creation_date",
        "actions",
      ],
      options: {
        headings: {
          fname: 'First Name',
          lname: 'Last Name',
          creation_date: 'Creation Date'
        },
        sortable: ['companyid', 'email', 'fname', 'lname',],
        filterable: ['companyid', 'email', 'fname', 'lname',],
        sortIcon: { base: 'fa', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort' },
        // perPage: 15,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },

      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,
      password: '',
      password2: '',
      error: false,
      passworderror: false,
      passlimit: false,
      managerData: [],
      email: "",
      oldemail: "",
      emailerror: false,
      id: 0,
      fname: "",
      lname: "",
      email: "",
      status: "",
      authority: "",
      passlimit: "",
      fax: 0,
      phone: 0,
    }
  },
  methods: {
    activeUser(id, role) {
      let data = JSON.stringify({
        status: 0,
        id: id,
        role
      })  
      this.$http
        .post("/sys_admin/edit/status", data)
        .then(response => {
          this.$toasted.show('Successfully Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    deactiveUser(id, role) {
      let data = JSON.stringify({
        status: 1,
        id: id,
        role
      })
      this.$http
        .post("/sys_admin/edit/status", data)
        .then(response => {
          this.$toasted.show('Successfully Status updated.', { type: 'success', duration: '3000' });
          this.loadData();
        })
        .catch(error => {
          this.$toasted.show("Error." + error, { type: "error", duration: "3000" });
        })
    },
    sendInfo(row) {
      this.id = row.managerid;
      this.fname = row.fname;
      this.lname = row.lname;
      this.email = row.email;
      this.status = row.status;
      this.authority = row.authority;
      this.passlimit = row.passlimit;
      this.fax = row.fax;
      this.phone = row.phone;
      this.$refs.editModal.show();
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            // companyid: this.$route.params.id,
            companyid: 5,
            email: this.email,
            authority: this.authority,
            passlimit: this.passlimit,
            fname: this.fname,
            lname: this.lname,
            fax: this.fax,
            phone: this.phone,
            status: this.status,
          });
          this.$http
            .post("/sys_admin/edit/manager", data)
            .then((response) => {
              if (response.status === 200) {
                this.$toasted.show("Update Successful.", {
                  type: "success",
                  duration: "3000",
                });
                this.$refs.editModal.hide();
                this.loadData();
              }
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    loadData() {
      this.$http.get('/sys_admin/get/manager')
        .then(response => {
          this.managerData = response.data.result;
          this.managerData = response.data.result.filter(wts => wts.addressline1 != "NP");
          this.managerData = response.data.result.filter(wts => wts.authority == 6);

        })
        .catch(error => {
          this.$toasted.show('Error.', { type: 'error', duration: '3000' })
        })
    },
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
